









































import {Component, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class GraphGenerator extends Vue {
  tileAmount: number = 15;
  target: number = 20;
}
